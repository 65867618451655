import React from 'react'
import Img from 'gatsby-image';
import Layout from '../components/layout'
import { StaticQuery, graphql } from 'gatsby';
import { fluidImage } from '../fragments';
import Helmet from 'react-helmet'
const Contact = () => (
  <StaticQuery
  query={graphql`
    query ContactQuery {
      contactImage: file(relativePath: { eq: "mark-autumns-268520-unsplash.jpg" }) {
        ...fluidImage
      }
    }
  `}
  render={data => (
<Layout>
<Helmet>
      <title>Tobias Meixner - Contact me</title>
    </Helmet>
<Img
fluid={data.contactImage.childImageSharp.fluid}
style={{
height: '185px',
// objectFit: 'cover'
}} alt="Bridge" />
  <div className={"quote"}>    
  Opportunities don't happen. You create them.
      <span>Chris Grosser</span> 
    </div>
    <h1 style={{
      textAlign: 'center'
    }}>Contact me</h1>        
    <form style={{
      margin: '0 auto',
      display: 'flex',
      flexFlow: 'column',
    }} name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" action="/thank-you">
      <div>
        <input id="nameField" placeholder="Your name" type="text" name="name" />
      </div>
      <div>
        <input placeholder="Your email" type="email" name="email" />
      </div>      
      <div>
        <textarea placeholder="Your message" name="message"></textarea>
      </div>
      <div style={{textAlign:'center'}}>
        <button style={{margin:'0 auto'}} type="submit">Send</button>
      </div>
      <input type="hidden" name="form-name" value="contact" />
    </form>
  </Layout>
  )} />
)
// class ContactPage extends React.Component {
//   componentDidMount(){
//     document.getElementById('nameField').focus()
//   }
//   render() {
//     return <Contact />
//   }
// }

export default Contact
